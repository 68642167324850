import { defineComponent } from 'vue'

import DsView from '@/components/DsView'
import { Model } from '@/components/DsForm'
import { Column } from '@/components/DsTable'

const clientModel: Model = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      label: 'Name',
      required: true,
    },
    lastname: {
      type: 'string',
      label: 'Lastname',
      required: true,
    },
    email: {
      type: 'string',
      label: 'Email',
      required: true,
      format: 'email',
    },
    phone: {
      type: 'string',
      label: 'Phone',
    },
    address: {
      type: 'string',
      label: 'Address',
    },
    zip: {
      type: 'string',
      label: 'Zip',
    },
    city: {
      type: 'string',
      label: 'City',
    },
    country: {
      type: 'string',
      label: 'Country',
    },
    comment: {
      type: 'string',
      label: 'Comment',
    },
  },
}

const columns: Column[] = [
  { key: 'name', header: 'Name' },
  { key: 'lastname', header: 'Lastname' },
  { key: 'email', header: 'Email' },
  { key: 'phone', header: 'Phone' },
  { key: 'address', header: 'Address' },
  { key: 'zip', header: 'Zip' },
  { key: 'city', header: 'City' },
  { key: 'country', header: 'Country' },
  { key: 'comment', header: 'Comment' },
]

const defaultClient = () => ({ email: '', name: '', lastname: '' })

export default defineComponent({
  name: 'Clients',
  components: { DsView },
  setup() {
    return () => (
      <DsView
        view={{
          collection: 'clients',
          title: 'Clients',
          model: clientModel,
          columns: columns,
          createButtonLabel: 'New client',
          rowKey: 'id',
        }}
        defaultValue={defaultClient}
      />
    )
  },
})
